import React from "react";
import { useState, useEffect } from "react";
import "./Message.css";
import PersonIcon from "@mui/icons-material/Person";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import WarningIcon from "@mui/icons-material/Warning";
import Avatar from "@mui/material/Avatar";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { DEMOGRAPHICS_SURVEY_URL } from "../constants";

export default function Message(props) {

  const [pressed, setPressed] = useState(false);

  const navigate = useNavigate();

  const resendMessages = () => {
    props.resend(props.message);

    // setTimeout(() => setPressed(false), 3000);
  };

  const hideReasoning = (input) => {
    let output = input;

    // Use a regular expression to remove all of steps 1 & 2
    output = output.replace(/^#### Step [1-2].*\n?.+/gm, '');
    // Use a regular expression to match and remove lines starting with ####
    output = output.replace(/^####.*\n?/gm, '');

    // Remove extra whitespace before output
    return output.trim();
  }

  function needsDoneButton() {
    return props.message.trim().endsWith("DONE");
  }

  // function handleContinue() {
  //   navigate("/usability");
  // }
  function handleContinue() {
    // Redirect directly to the Qualtrics survey
    window.location.href = (DEMOGRAPHICS_SURVEY_URL + "?id=" + localStorage.getItem("user_id"));
  }

  return (
    <>
      {props.message != " " || props.error ? (
        <div className="message-box">
          <div className="icon">
            {props.user ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Avatar
                  sx={{
                    bgcolor: "secondary.main",
                    width: "2rem",
                    height: "2rem",
                  }}
                ></Avatar>
                <b style={{ padding: ".5rem" }}>You</b>
              </div>
            ) : (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Avatar
                  sx={{ bgcolor: "#861F41", width: "2rem", height: "2rem" }}
                >
                  <SupportAgentIcon />
                </Avatar>
                <b style={{ padding: ".5rem" }}>EFTeacher</b>
              </div>
            )}
          </div>

          <div className="message">
            <div dangerouslySetInnerHTML={{ __html: hideReasoning(needsDoneButton() ? props.message.trim().slice(0, -4) : props.message) }}></div>
            {needsDoneButton() ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button variant="contained" onClick={handleContinue}>
                  Continue
                </Button>
              </div>
            ) : null}
          </div>

          {props.error && props.new && props.index === props.length - 1 ? (
            <div className="send-error">
              <WarningIcon style={{ color: " #B00020", fontSize: "2.5rem" }} />
              <div>Failed to send message</div>
              <div className="try-again" onClick={() => resendMessages()}>
                resend
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  );
}
